import React, { useState, useEffect, useRef } from 'react';
import UserProfile from './UserProfile'; // Importiere die Komponente für das Profilbild
import LogoutButton from './LogoutButton';
import LoginButton from './LoginButton';
import { Link, useLocation } from 'react-router-dom';

const Header = ({ user, isLoggedIn }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null); // Ref für das Dropdown, um es zu identifizieren
  const location = useLocation(); // Aktuellen Pfad bekommen
  const onProfilePage = location.pathname === '/profile'; 
  const onShopPage = location.pathname === '/shop'; 

  // Event-Listener, um Klicks außerhalb des Dropdowns zu erkennen
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false); // Schließe das Dropdown, wenn außerhalb geklickt wird
      }
    };

    // Binden des Event-Listeners
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Entfernen des Event-Listeners, wenn die Komponente demontiert wird
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  return (
    <header className="bg-blue-500 text-white p-4 flex justify-between items-center">
      <div className=''>
        <h1>VoiceShiftDE</h1>
      </div>
      <div className="flex items-center space-x-4">
        {isLoggedIn ? (
          <div className="relative flex items-center" ref={dropdownRef}>
            {/* Link neben dem Profilbild platzieren und stilisieren */}
            <Link to={onProfilePage ? "/" : "/profile"} className="profile-link">
              <p>{onProfilePage ? "Home" : "Profil"}</p>
            </Link>
            <Link to={onShopPage ? "/" : '/shop'} className='profile-link'> 
              <p>{onShopPage ? "Home" : "Shop"}</p>
            </Link>
            <div onClick={toggleDropdown} className="cursor-pointer">
              <UserProfile user={user} />
            </div>
            {dropdownVisible && (
              <div className="dropdown">
                <LogoutButton className="logout-button" />
              </div>
            )}
          </div>
        ) : (
          <LoginButton />
        )}
      </div>
    </header>
  );
};

export default Header;