import React, { useState, useEffect } from 'react';


const LoginButton = ({ onLogin }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);

  // Füge einen einfachen Check hinzu, um sicherzustellen, dass onLogin eine Funktion ist
  const handleButtonClick = () => {
    window.location.href = `https://discord.com/api/oauth2/authorize?client_id=${process.env.REACT_APP_DISCORD_CLIENT_ID}&redirect_uri=${encodeURIComponent(process.env.REACT_APP_DISCORD_REDIRECT_URI)}&response_type=code&scope=identify%20email&prompt=consent`;
    setIsLoggedIn(true);
  };

  return (
    <button
      className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
      onClick={handleButtonClick} // Verwende handleButtonClick als event handler
    >
      Login with Discord
    </button>
  );
};

export default LoginButton;