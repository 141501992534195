import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import { toast } from 'react-toastify';
import { FaCoins, FaTrash, FaShoppingCart } from 'react-icons/fa';
import monteFace from '../assets/monte_face.png';

const MODEL_COST = 15;

const Shop = ({ user }) => {
  const [userPoints, setUserPoints] = useState(0);
  const [userModels, setUserModels] = useState([]);
  const [availableModels, setAvailableModels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (user) {
      loadUserData();
    }
  }, [user]);

  const loadUserData = async () => {
    try {
      setLoading(true);
      const [pointsRes, userModelsRes, availableModelsRes] = await Promise.all([
        axios.get(`/api/user/points/${user.id}`),
        axios.get(`/api/models/user/${user.id}`),
        axios.get('/api/models/available')
      ]);

      setUserPoints(pointsRes.data.points);
      setUserModels(userModelsRes.data.map(item => ({
        id: item.param_models.id,
        name: item.param_models.model,
        imageUrl: monteFace
      })));
      
      const availableIds = userModelsRes.data.map(m => m.model_id);
      setAvailableModels(availableModelsRes.data
        .filter(model => !availableIds.includes(model.id) && !model.private)
        .map(model => ({
          id: model.id,
          name: model.model,
          imageUrl: monteFace
        }))
      );
    } catch (error) {
      setError('Failed to load shop data');
      toast.error('Failed to load shop data');
    } finally {
      setLoading(false);
    }
  };

  const buyModel = async (model) => {
    if (userPoints < MODEL_COST) {
      toast.error('Nicht genügend Punkte!');
      return;
    }

    const confirmPurchase = window.confirm(`Dieses Modell kostet ${MODEL_COST} V-Punkte. Möchten Sie fortfahren?`);
    if (!confirmPurchase) return;

    try {
      setLoading(true);
      await axios.post('/api/models/buy', {
        userId: user.id,
        modelId: model.id,
        cost: MODEL_COST
      });

      toast.success('Model erfolgreich gekauft!');
      loadUserData();
    } catch (error) {
      toast.error('Fehler beim Kauf des Modells');
    } finally {
      setLoading(false);
    }
  };

  const removeModel = async (modelId) => {
    const confirmRemove = window.confirm('Wenn du die Stimme entfernst, musst du sie mit 15 Punkten erneut kaufen. Fortfahren?');
    if (!confirmRemove) return;

    try {
      setLoading(true);
      await axios.delete(`/api/models/remove/${user.id}/${modelId}`);
      toast.success('Model erfolgreich entfernt');
      loadUserData();
    } catch (error) {
      toast.error('Fehler beim Entfernen des Modells');
    } finally {
      setLoading(false);
    }
  };

  if (loading) return (
    <div className="flex justify-center items-center min-h-screen bg-gray-900">
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-500"></div>
    </div>
  );

  if (error) return (
    <div className="text-center text-red-500 p-4 bg-gray-900">
      <p className="text-xl">{error}</p>
      <button
        onClick={loadUserData}
        className="mt-4 bg-purple-500 text-white px-4 py-2 rounded-lg hover:bg-purple-600 transition-colors"
      >
        Retry
      </button>
    </div>
  );

  return (
    <div className="min-h-screen bg-gray-900 p-4 md:p-8">
      <div className="max-w-7xl mx-auto">
        {/* Header */}
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-gradient-to-r from-purple-600 to-blue-500 rounded-xl p-6 mb-8 shadow-2xl"
        >
          <div className="flex justify-between items-center">
            <h1 className="text-3xl md:text-4xl font-bold text-white">
              VoiceShift Shop
            </h1>
            <div className="flex items-center space-x-2 bg-white bg-opacity-20 rounded-full px-4 py-2">
              <FaCoins className="text-yellow-400 text-xl" />
              <span className="text-white font-bold text-xl">
                {userPoints} Points
              </span>
            </div>
          </div>
        </motion.div>

        {/* Owned Models */}
        <div className="mb-12">
          <h2 className="text-2xl font-bold text-white mb-6 border-b border-gray-700 pb-2">
            Ihre Stimmen
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            <AnimatePresence>
              {userModels.map((model) => (
                <motion.div
                  key={model.id}
                  initial={{ opacity: 0, scale: 0.9 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.9 }}
                  className="bg-gray-800 rounded-xl overflow-hidden shadow-lg hover:shadow-2xl transition-shadow"
                >
                  <div className="p-6">
                    <div className="flex items-center justify-between mb-4">
                      <h3 className="text-xl font-semibold text-white">{model.name}</h3>
                      <img src={model.imageUrl} alt={model.name} className="w-12 h-12 rounded-full" />
                    </div>
                    <button
                      onClick={() => removeModel(model.id)}
                      className="w-full bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 transition-colors flex items-center justify-center space-x-2"
                    >
                      <FaTrash />
                      <span>Entfernen</span>
                    </button>
                  </div>
                </motion.div>
              ))}
            </AnimatePresence>
          </div>
        </div>

        {/* Available Models */}
        <div>
          <h2 className="text-2xl font-bold text-white mb-6 border-b border-gray-700 pb-2">
            Verfügbare Stimmen
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            <AnimatePresence>
              {availableModels.map((model) => (
                <motion.div
                  key={model.id}
                  initial={{ opacity: 0, scale: 0.9 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.9 }}
                  className="bg-gray-800 rounded-xl overflow-hidden shadow-lg hover:shadow-2xl transition-shadow"
                >
                  <div className="p-6">
                    <div className="flex items-center justify-between mb-4">
                      <h3 className="text-xl font-semibold text-white">{model.name}</h3>
                      <img src={model.imageUrl} alt={model.name} className="w-12 h-12 rounded-full" />
                    </div>
                    <div className="flex items-center justify-between mb-4">
                      <span className="text-purple-400 font-semibold">{MODEL_COST} Points</span>
                    </div>
                    <button
                      onClick={() => buyModel(model)}
                      className="w-full bg-purple-500 text-white px-4 py-2 rounded-lg hover:bg-purple-600 transition-colors flex items-center justify-center space-x-2"
                      disabled={userPoints < MODEL_COST}
                    >
                      <FaShoppingCart />
                      <span>Kaufen</span>
                    </button>
                  </div>
                </motion.div>
              ))}
            </AnimatePresence>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Shop;