import React from 'react';


const UserProfile = ({ user }) => {
  // Überprüfung, ob der Benutzer eingeloggt ist.
  if (!user) {
    return <div>Kein Bild verfügbar</div>;
  }

  // Bilden der URL für das Profilbild
  const avatarUrl = user.avatar
    ? `https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}.png`
    : `https://cdn.discordapp.com/embed/avatars/${user.discriminator % 5}.png`; // Fallback auf Standardavatar, falls kein Avatar gesetzt ist

  return (
    <div className="flex items-center">
      <img src={avatarUrl} className="rounded-full h-12 w-12" />
    </div>
  );
};

export default UserProfile;