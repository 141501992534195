import React from 'react';
import mrKrabsDollarSong from '../assets/mr_krabs_dollar.mp3'
import mrKrabs from '../assets/mr_krabs.png'
import microphone from '../assets/microphone.webp'
import tts from '../assets/tts.webp'
import imageCreation from '../assets/image_creation.webp'
import ReactAudioPlayer from 'react-audio-player';

const HomePage = () => {
  return (
    <div className="min-h-screen bg-gray-900 text-gray-200">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <header className="text-center mb-16">
          <h1 className="text-4xl font-bold mb-6">VoiceShiftDE</h1>
          <p className="mb-8">Entdecke die Zukunft der KI-gesteuerten Medien auf unserem Discord Server!</p>
          <button className="bg-blue-600 hover:bg-blue-800 text-white font-bold py-3 px-8 rounded-lg transition duration-300">
            <a href='https://discord.gg/nzU2e2Asgy' target="_blank" rel="noopener noreferrer">
              KI Cover erstellen
            </a>
          </button>
        </header>

        {/* Beispiel KI Cover Bereich */}
        <section className="mb-24 text-center">
          <h2 className="text-3xl font-bold mb-12 pt-10 border-dotted border-t">Beispiel KI Cover</h2>
          <div className="flex flex-col items-center">
            <h3 className="text-lg font-bold mb-4 border-t border-dotted pt-10 mb-10">I NEED A DOLLAR (Mr Krabs Version)</h3>
            <div className="flex flex-col md:flex-row items-center justify-center gap-4">
              <img src={mrKrabs} alt="Mr Krabs" className="shadow-2xl w-32 h-32 rounded-lg border-t border-b" />
              <div>
                  <ReactAudioPlayer 
                    src={mrKrabsDollarSong} 
                    controls
                  />
              </div>
            </div>
          </div>
        </section>

            <section className="mb-24 text-center">
                <h2 className="text-4xl font-bold mb-12 border-t border-dotted pt-10">Unsere Funktionen</h2>
                <div className="grid md:grid-cols-3 gap-16">
                    {/* AI Cover Creation */}
                    <div className="flex flex-col items-center p-6 bg-gray-800 rounded-lg shadow-xl">
                    <h3 className="text-3xl font-bold mb-6">AI Cover Creation</h3>
                    <p className="mb-8">Erstelle beeindruckende Cover mit unserem KI-basierten Design-Tool.</p>
                    <img src={microphone} alt="Cover Creation" className="h-40 w-40 object-cover rounded-full mb-6" />
                    <button className="bg-green-600 hover:bg-green-800 text-white font-bold py-2 px-6 rounded-lg transition duration-300 transform hover:scale-105">
                        <a href='https://discord.gg/nzU2e2Asgy' target="_blank" rel="noopener noreferrer">
                        Mehr erfahren
                        </a>
                    </button>
                    </div>

                    {/* TTS Bot */}
                    <div className="flex flex-col items-center p-6 bg-gray-800 rounded-lg shadow-xl">
                    <h3 className="text-3xl font-bold mb-6">TTS Bot</h3>
                    <p className="mb-8">Verwandle Text in natürliche Sprache mit unserer fortschrittlichen Text-to-Speech-Technologie.</p>
                    <img src={tts} alt="TTS Bot" className="h-40 w-40 object-cover rounded-full mb-6" />
                    <button className="bg-green-600 hover:bg-green-800 text-white font-bold py-2 px-6 rounded-lg transition duration-300 transform hover:scale-105">
                        <a href='https://discord.gg/nzU2e2Asgy' target="_blank" rel="noopener noreferrer">
                        Mehr erfahren
                        </a>
                    </button>
                    </div>

                    {/* AI Image Generation */}
                    <div className="flex flex-col items-center p-6 bg-gray-800 rounded-lg shadow-xl">
                    <h3 className="text-3xl font-bold mb-6">AI Image Generation</h3>
                    <p className="mb-8">Generiere einzigartige Bilder und Grafiken mit unserer KI-gesteuerten Bildgenerierung.</p>
                    <img src={imageCreation} alt="Image Generation" className="h-40 w-40 object-cover rounded-full mb-6" />
                    <button className="bg-green-600 hover:bg-green-800 text-white font-bold py-2 px-6 rounded-lg transition duration-300 transform hover:scale-105">   
                        <a href='https://discord.gg/nzU2e2Asgy' target="_blank" rel="noopener noreferrer">
                        Mehr erfahren
                        </a>
                    </button>
                    </div>
                </div>
                </section>
      </div>
    </div>
  );
};

export default HomePage;