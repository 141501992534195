import React, { useState, useEffect } from 'react';
import { AiOutlineUser, AiOutlineDownload, AiOutlineBuild } from 'react-icons/ai';
import axios from 'axios';
import '../index.css';

const truncateTitle = (title = '', maxLength = 50) => {
    return title.length > maxLength ? `${title.substring(0, maxLength)}...` : title;
};

const ProfilePage = ({ user }) => {
    const [songHistory, setSongHistory] = useState([]);
    const [statistics, setStatistics] = useState({
        models: 0,
        points: 0,
        creations: 0,
        memberSince: ''
    });

    const limitedSongHistory = songHistory.slice(0, 9);   // Begrenze die Anzahl der angezeigten Songs auf 9

    useEffect(() => {
        const loadStatisticsAndHistory = async () => {
            try {
                const [memberResponse, modelsResponse, pointsResponse, creationsResponse, songHistoryResponse] = await Promise.all([
                    axios.get(`${process.env.REACT_APP_BACKEND_URL}/user/member-since/${user.id}`),
                    axios.get(`${process.env.REACT_APP_BACKEND_URL}/user/models-count/${user.id}`),
                    axios.get(`${process.env.REACT_APP_BACKEND_URL}/user/points/${user.id}`),
                    axios.get(`${process.env.REACT_APP_BACKEND_URL}/user/creations/${user.id}`),
                    axios.get(`${process.env.REACT_APP_BACKEND_URL}/user/song-history/${user.id}`)
                ]);

                setStatistics({
                    memberSince: memberResponse.data.created_at,
                    models: modelsResponse.data.count,
                    points: pointsResponse.data.points,
                    creations: creationsResponse.data.usages
                });

                const historyWithThumbnails = songHistoryResponse.data.map(song => ({
                    ...song,
                    imageUrl: `https://img.youtube.com/vi/${song.song_id}/0.jpg`,
                }));
                setSongHistory(historyWithThumbnails);
            } catch (error) {
                console.error('Error loading profile data:', error);
            }
        };

        if (user) {
            loadStatisticsAndHistory();
        }
    }, [user]);

    const formatDate = (dateString, includeTime = false) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric', ...(includeTime && { hour: '2-digit', minute: '2-digit', second: '2-digit' }) };
        return new Date(dateString).toLocaleDateString('de-DE', options);
    };

    return (
        <div className="bg-gray-800 text-gray-100 overflow-hidden">
            <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
                <div className="flex items-center">
                    <div className="ml-4">
                        <p className='text-4xl'>{user.username}</p>
                        <p className="profile-join-date text-sm text-gray-400">Mitglied seit {formatDate(statistics.memberSince)}</p>
                    </div>
                </div>
            </div>
            <div className="profile-statistics border-t border-gray-700">
                <div className="statistic-container px-2 py-3 grid grid-cols-3 gap-2 md:gap-4 sm:px-6">
                    <div className="statistic card flex flex-col items-center justify-center">
                        <h2 className="statistic-label text-xs md:text-sm flex items-center justify-center"><AiOutlineUser className="icon text-white text-base md:text-lg" /><p className='font-bold text-white'>Modelle</p></h2>
                        <p className="statistic-value text-lg md:text-xl font-semibold text-gray-900"><p className='text-white font-bold'>{statistics.models}</p></p>
                    </div>
                    <div className="statistic card flex flex-col items-center justify-center">
                        <h2 className="statistic-label text-xs md:text-sm font-medium flex items-center justify-center"><AiOutlineDownload className="icon text-white text-base md:text-lg" /><p className='font-bold text-white'>Punkte</p></h2>
                        <p className="statistic-value text-lg md:text-xl font-semibold text-gray-900"><p className='text-white font-bold'>{statistics.points}</p></p>
                    </div>
                    <div className="statistic card flex flex-col items-center justify-center">
                        <h2 className="statistic-label text-xs md:text-sm font-medium flex items-center justify-center"><AiOutlineBuild className="icon text-white text-base md:text-lg" /><p className='font-bold text-white'>Kreationen</p></h2>
                        <p className="statistic-value text-lg md:text-xl font-semibold"><p className='text-white font-bold'>{statistics.creations}</p></p>
                    </div>
                </div>
            </div>
            <div className="song-history border-t border-gray-700 px-4 py-5 sm:px-6">
                <h3 className="text-4xl text-center mb-4 mt-4 leading-6 font-medium text-gray-100">Song-Historie</h3>
                <ul className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                    {limitedSongHistory.map((song, index) => (
                        <li key={index} className="bg-slate-700 rounded-lg shadow-2xl shadow-white overflow-hidden">
                            <div className="p-4">
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center">
                                        <div className="flex-shrink-0 flex items-center justify-center w-12 h-12 bg-pink-600 text-white text-lg font-bold rounded-full">
                                            {index + 1}
                                        </div>
                                        <div className="ml-4">
                                            <a href={`https://www.youtube.com/watch?v=${song.song_id}`} target="_blank" rel="noopener noreferrer" className="text-gray-900 font-medium hover:text-gray-600">
                                                <p className='font-bold text-white sm:text-sm lg:text-lg'>{truncateTitle(song.song_name, 38)}</p>
                                            </a>
                                            <p className="text-gray-400 text-lg lg:text-2xl">{song.model}</p>
                                        </div>
                                    </div>
                                    <img className="w-24 h-24 bg-gray-300 border mt-8 rounded-full flex-shrink-0" src={song.imageUrl} alt={song.song_name} />
                                </div>
                                <div className="flex items-center justify-between mt-4">
                                <a href={`/api/songs/${song.song_id}/${encodeURIComponent(song.song_name)} (${encodeURIComponent(song.model)} Ver).mp3`} download rel="noopener noreferrer" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full text-xs">
                                    Download
                                </a>
                                <p className='text-sm text-white'>{formatDate(song.created_at, true)}</p>
                                </div>
                                <div className="mt-2">
                                <audio controls className="w-full">
                                    <source src={`/api/songs/${song.song_id}/${encodeURIComponent(song.song_name)} (${encodeURIComponent(song.model)} Ver).mp3`} type="audio/mpeg" />
                                    Your browser does not support the audio element.
                                </audio>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default ProfilePage;
