import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ children, isLoggedIn, ...rest }) => {
  // Stellen Sie sicher, dass alle zusätzlichen Props (wie `user`) an das `children` Element weitergegeben werden
  const childrenWithProps = React.cloneElement(children, rest);
  
  return isLoggedIn ? childrenWithProps : <Navigate to="/" replace />;
};


export default PrivateRoute;