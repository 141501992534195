import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ProfilePage from './components/ProfilePage';
import Header from './components/Header';
import HomePage from './components/HomePage';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import Shop from './components/Shop'
import ImpressumPage from './components/Impressum'; // Ihre Impressum-Seite
import Footer from './components/Footer'; // Importieren Sie die Footer-Komponente
import CookieConsent from "react-cookie-consent";

const App = () => {
  const [user, setUser] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);


  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        // Check Cookies for accesstoken
        
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/auth-check`, { withCredentials: true });
        const { isLoggedIn, username } = response.data;
        if (isLoggedIn) {
          setUser({ username });
          setIsLoggedIn(true);
          // Wenn der Benutzer eingeloggt ist, aber kein AccessToken im Cookie vorhanden ist, Benutzerdetails abrufen
          const accessToken = getCookieValue('accessToken');

          try {
            const user_response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/user`, { withCredentials: true });
            setUser(user_response.data);
          } catch (error) {
            console.error('Error fetching user data:', error);
            setUser(null); // Setzen Sie den Benutzer auf null, wenn ein Fehler auftritt
          }

        } else {
          // Der Benutzer ist nicht eingeloggt
          setUser(null);
          setIsLoggedIn(false);
        }
      } catch (error) {
        console.error('Error checking auth status:', error);
        setUser(null);
        setIsLoggedIn(false);
      }
    };

    checkAuthStatus();
  }, []);

  const getCookieValue = (name) => (
    document.cookie.split('; ').find(row => row.startsWith(`${name}=`))?.split('=')[1]
  );


  return (
    <Router>
      <div className="flex flex-col min-h-screen"> {/* Flex-Container für die Gesamtapp */}
        
        <Header user={user} isLoggedIn={isLoggedIn} />
        
        {/* Inhaltsbereich mit flex-grow, damit er sich ausdehnt und den verfügbaren Platz einnimmt */}
        <div className="flex-grow">
          <Routes>
            <Route path="/" element={<HomePage user={user} />} />
            <Route path="/profile" element={
              <PrivateRoute isLoggedIn={isLoggedIn}>
                <ProfilePage user={user} />
              </PrivateRoute>
            } />
            <Route path="/shop" element={
              <PrivateRoute isLoggedIn={isLoggedIn}>
                <Shop user={user} />
              </PrivateRoute>
            } />
            <Route path="/impressum" element={<ImpressumPage />} />
          </Routes>
        </div>
        
        <Footer />
        <CookieConsent
          location="bottom"
          buttonText="Ich stimme zu"
          cookieName="userConsent"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          expires={150} // Gültigkeit des Cookies in Tagen
          onAccept={() => {
            // Logik, die ausgeführt wird, wenn der Benutzer zustimmt
          }}
        >
          Diese Website verwendet Cookies, um die Benutzererfahrung zu verbessern.{" "}
        </CookieConsent>
      </div>
    </Router>
  );
};


export default App;
