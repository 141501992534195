import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-gray-200 text-center p-4 w-full">
      <p>© {new Date().getFullYear()} VoiceShiftDE. Alle Rechte vorbehalten.</p>
      <a href="/impressum" className="text-gray-400 hover:text-gray-300">Impressum</a>
    </footer>
  );
};

export default Footer;
