import React from 'react';
import axios from 'axios';



const LogoutButton = () => {
  const handleLogout = async () => {
    try {
      // Senden Sie eine Anfrage an den Server, um die Cookies zu löschen
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/logout`, { withCredentials: true });

      
      if (response.status === 200) {
        // Löschen Sie das Cookie, indem Sie seine Gültigkeitsdauer auf ein vergangenes Datum setzen
        document.cookie = 'accessToken=; Max-Age=-99999999; path=/; domain=voiceshiftde.site; secure; samesite=none';
        // Seite neu laden
        window.location.reload();
      }

    } catch (error) {
      console.error('Logout failed:', error);
    }
  };


    return (
      <button className="w-full text-center text-white bg-red-500 hover:bg-red-700 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" onClick={handleLogout}>
        Logout
      </button>
  );
};

export default LogoutButton;